import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { HowItWorks, TechnicalOverview, EfficiencyComparison } from '@/components/sections';
import { CTASection } from '@/components/ui/cta-section';
import { Shield, ArrowRightLeft, Lock, Clock, FileCheck, Zap, Server, Network, Database, Laptop, BarChart } from 'lucide-react';
import { Button } from '@/components/ui/button';
import branding from '@/lib/config/branding.json';

interface Organization {
  name: string;
  logo: string;
  alt: string;
  url: string;
}

const ATDFeatures = () => {
  const features = [
    {
      icon: <Shield className="w-12 h-12 text-primary" />,
      title: "Air-Gap Security",
      items: [
        "Maintains air-gap integrity",
        "Zero network exposure",
        "Physical security controls"
      ]
    },
    {
      icon: <ArrowRightLeft className="w-12 h-12 text-primary" />,
      title: "Secure Transfer",
      items: [
        "Bi-directional data transfer",
        "Automated file validation",
        "Controlled data flow"
      ]
    },
    {
      icon: <Lock className="w-12 h-12 text-primary" />,
      title: "Compliance Ready",
      items: [
        "NIST compliance",
        "Audit trail logging",
        "Access control management"
      ]
    },
    {
      icon: <Zap className="w-12 h-12 text-[#1BC595]" />,
      title: "Operational Excellence",
      items: [
        "Optimized transfer protocols",
        "Smart queuing system",
        "Minimal system impact"
      ]
    },
    {
      icon: <Clock className="w-12 h-12 text-[#1BC595]" />,
      title: "Time Efficiency",
      items: [
        "Scheduled transfers",
        "Automated workflows",
        "Reduced manual overhead"
      ]
    },
    {
      icon: <FileCheck className="w-12 h-12 text-[#1BC595]" />,
      title: "Asset Management",
      items: [
        "Real-time inventory",
        "Version control",
        "Change tracking"
      ]
    }
  ];

  return (
    <section className="py-16 bg-background">
      <div className="container mx-auto px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-card backdrop-blur-sm rounded-lg p-8 border border-border/30 shadow-lg hover:shadow-xl transition-all duration-300 hover:border-primary/50 hover:bg-card/90 hover:-translate-y-0.5 group">
                <div className="p-4 bg-primary/20 rounded-lg w-fit mb-6 transition-colors duration-300 group-hover:bg-primary/30">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold mb-4 text-primary">{feature.title}</h3>
                <ul className="space-y-2">
                  {feature.items.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const OverviewSection = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-background to-muted/30">
      <div className="container mx-auto px-4">
        <div className="max-w-7xl mx-auto">
          <SectionHeader
            title="The Hidden Risks in Air-Gapped Systems"
            description="Manual processes create security vulnerabilities you may not see"
            className="text-center mb-12"
          />
          
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-card backdrop-blur-sm rounded-lg p-8 border border-border/30 shadow-lg hover:shadow-xl transition-all duration-300 hover:border-primary/50 hover:bg-card/90 hover:-translate-y-0.5 group">
              <div className="flex items-start gap-4">
                <div className="p-3 bg-primary/20 rounded-lg transition-colors duration-300 group-hover:bg-primary/30">
                  <Server className="w-6 h-6 text-primary" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-primary">The Air-Gap Challenge</h3>
                  <p className="text-muted-foreground leading-relaxed">
                    Air-gapped systems are physically isolated from unsecured networks, including the internet, 
                    providing maximum security for sensitive data. However, this isolation creates significant 
                    challenges for system updates, patch management, and data transfer - essential tasks for 
                    maintaining system health and security.
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-card backdrop-blur-sm rounded-lg p-8 border border-border/30 shadow-lg hover:shadow-xl transition-all duration-300 hover:border-primary/50 hover:bg-card/90 hover:-translate-y-0.5 group">
              <div className="flex items-start gap-4">
                <div className="p-3 bg-primary/20 rounded-lg transition-colors duration-300 group-hover:bg-primary/30">
                  <Laptop className="w-6 h-6 text-primary" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-primary">Asset Management Hurdles</h3>
                  <p className="text-muted-foreground leading-relaxed mb-4">
                    Tracking and managing air-gapped assets presents unique challenges that traditional asset 
                    management solutions can't address:
                  </p>
                  <ul className="space-y-2">
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Difficulty monitoring accurate inventory of disconnected systems</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Limited visibility into hardware and software configurations</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Challenges in tracking system health and compliance status</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-card backdrop-blur-sm rounded-lg p-8 border border-border/30 shadow-lg hover:shadow-xl transition-all duration-300 hover:border-primary/50 hover:bg-card/90 hover:-translate-y-0.5 group">
              <div className="flex items-start gap-4">
                <div className="p-3 bg-primary/20 rounded-lg transition-colors duration-300 group-hover:bg-primary/30">
                  <BarChart className="w-6 h-6 text-primary" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-primary">Comprehensive Asset Intelligence</h3>
                  <p className="text-muted-foreground leading-relaxed mb-4">
                    Our ATD solution transforms how you track and manage air-gapped assets:
                  </p>
                  <ul className="space-y-2">
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Automated asset discovery and inventory management</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Real-time hardware and software configuration tracking</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Detailed system health and compliance reporting</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Proactive license utilization monitoring</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Automated license reclamation for cost savings</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bg-card backdrop-blur-sm rounded-lg p-8 border border-border/30 shadow-lg hover:shadow-xl transition-all duration-300 hover:border-primary/50 hover:bg-card/90 hover:-translate-y-0.5 group">
              <div className="flex items-start gap-4">
                <div className="p-3 bg-primary/20 rounded-lg transition-colors duration-300 group-hover:bg-primary/30">
                  <Lock className="w-6 h-6 text-primary" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-primary">License Management & Cost Control</h3>
                  <p className="text-muted-foreground leading-relaxed mb-4">
                    ATD's intelligent license management capabilities help organizations optimize their software investments:
                  </p>
                  <ul className="space-y-2">
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Automatic detection of unused or underutilized licenses</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Streamlined license reclamation workflows</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Prevention of duplicate license purchases</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">License compliance monitoring and reporting</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="w-1.5 h-1.5 rounded-full bg-primary mt-2"></span>
                      <span className="text-muted-foreground">Historical usage tracking for better procurement planning</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const AirGapTransferPage = () => {
  return (
    <div className="min-h-screen">
      <section className="pt-32 pb-20 bg-background">
        <div className="container mx-auto px-4">
          <div className="max-w-7xl mx-auto text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-6 text-foreground">
              60% of Air-Gapped Systems
              <span className="shimmer-text-green block mt-2">Are At Risk</span>
            </h1>
            <p className="text-xl md:text-2xl text-muted-foreground mb-8 leading-relaxed max-w-4xl mx-auto">
              Manual transfer processes create security vulnerabilities that most organizations don't even realize
            </p>
            <div className="bg-card/50 backdrop-blur-sm border border-border/50 rounded-lg p-8 mb-12 max-w-4xl mx-auto">
              <p className="text-lg text-muted-foreground/90">
                Discover how automated, secure data transfer eliminates these hidden risks while 
                dramatically improving operational efficiency in your air-gapped environments.
              </p>
            </div>
          </div>
        </div>
      </section>

      <OverviewSection />
      
      <section className="py-16 bg-background">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="bg-card backdrop-blur-sm rounded-lg p-8 border border-border/30 shadow-lg">
              <h3 className="text-2xl font-semibold mb-4 text-center text-primary">
                Beyond Traditional Air-Gap Security
              </h3>
              <p className="text-lg text-muted-foreground text-center mb-6">
                While most solutions focus only on physical isolation, modern air-gap security 
                requires intelligent automation to eliminate human error and ensure consistent 
                security baselines.
              </p>
              <div className="grid md:grid-cols-3 gap-6 text-center">
                <div className="p-4">
                  <h4 className="font-semibold text-foreground mb-2">Manual Process</h4>
                  <p className="text-muted-foreground">High risk of human error</p>
                </div>
                <div className="p-4">
                  <h4 className="font-semibold text-foreground mb-2">Basic Air-Gap</h4>
                  <p className="text-muted-foreground">Physical isolation only</p>
                </div>
                <div className="p-4">
                  <h4 className="font-semibold text-primary mb-2">ATD Solution</h4>
                  <p className="text-muted-foreground">Automated security & compliance</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ATDFeatures />
      <HowItWorks />
      <TechnicalOverview />
      <EfficiencyComparison />
      
      <section className="py-16 bg-background">
        <div className="container mx-auto px-4">
          <div className="max-w-5xl mx-auto">
            <div className="grid md:grid-cols-2 gap-8 items-stretch">
              <div className="bg-card backdrop-blur-sm rounded-lg p-8 border border-border/30 shadow-lg flex flex-col">
                <div className="flex-grow">
                  <h3 className="text-2xl font-semibold mb-6 text-primary">Schedule a Demo</h3>
                  <p className="text-muted-foreground mb-6">
                    See firsthand how ATD can transform your air-gapped systems management while 
                    maintaining the highest security standards.
                  </p>
                </div>
                <Button 
                  size="lg"
                  className="w-full bg-primary hover:bg-primary/90"
                >
                  Book Your Demo
                </Button>
              </div>
              
              <div className="bg-card backdrop-blur-sm rounded-lg p-8 border border-border/30 shadow-lg flex flex-col">
                <div className="flex-grow">
                  <h3 className="text-2xl font-semibold mb-6 text-primary">Talk to an Expert</h3>
                  <p className="text-muted-foreground mb-6">
                    Get a personalized assessment of how ATD can be implemented in your 
                    specific environment.
                  </p>
                </div>
                <Button 
                  size="lg"
                  variant="outline"
                  className="w-full border-primary text-primary hover:bg-primary hover:text-background"
                >
                  Contact Us
                </Button>
              </div>
            </div>

            {branding.global.trustedOrganizations.enabled && (
              <div className="mt-12 text-center">
                <p className="text-sm text-muted-foreground mb-4">{branding.global.trustedOrganizations.title}</p>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                  {branding.global.trustedOrganizations.organizations.map((org: Organization, index: number) => (
                    <a 
                      key={index}
                      href={org.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group transition-opacity duration-300 hover:opacity-80"
                    >
                      {org.logo ? (
                        <img 
                          src={org.logo} 
                          alt={org.alt} 
                          className="h-12 w-full object-contain filter grayscale opacity-50 group-hover:grayscale-0 group-hover:opacity-100 transition-all duration-300"
                        />
                      ) : (
                        <div className="h-12 bg-muted rounded" />
                      )}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <CTASection
        title="Ready to Secure Your Air-Gapped Systems?"
        description="Transform your air-gapped system management with our ATD solution"
      />
    </div>
  );
};

export default AirGapTransferPage;
export { AirGapTransferPage };