import React from 'react';
import { Shield, Database, Workflow } from 'lucide-react';
import { Button } from '../ui/button';

const Hero = () => {
  return (
    <header className="pt-[200px] pb-40 bg-background">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl md:text-7xl font-bold mb-6 text-foreground leading-tight">
            Advanced Systems {' '}
            <span className="text-foreground">Management</span> Solutions
          </h1>
          <p className="text-xl md:text-2xl mb-6 leading-relaxed max-w-3xl mx-auto">
            <span className="shimmer-text-green">Empowering Enterprise IT with SCCM Automation and Air-Gapped Systems Management</span>
          </p>
          <div className="bg-card/50 backdrop-blur-sm border border-border/50 rounded-lg p-4 mb-12 max-w-2xl mx-auto shadow-sm">
            <p className="text-lg text-muted-foreground/90">
              Featuring{' '}
              <span className="font-semibold text-foreground">ATD (Air-gap Transfer Device)</span>{' '}
              for Secure Systems Management in Air-Gapped Environments
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8 mb-12 max-w-3xl mx-auto">
            <div className="flex items-center space-x-3 justify-center">
              <Database className="w-6 h-6 text-[#1BC595]" />
              <span className="text-muted-foreground">SCCM Automation</span>
            </div>
            <div className="flex items-center space-x-3 justify-center">
              <Shield className="w-6 h-6 text-[#1BC595]" />
              <span className="text-muted-foreground">Air-Gapped Systems</span>
            </div>
            <div className="flex items-center space-x-3 justify-center">
              <Workflow className="w-6 h-6 text-[#1BC595]" />
              <span className="text-muted-foreground">Custom Solutions</span>
            </div>
          </div>
          
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Button 
              size="lg" 
              className="bg-[#1BC595] hover:bg-[#1BC595]/90"
              onClick={() => window.location.href = '/solutions'}
            >
              Explore Our Solutions
            </Button>
            <Button 
              size="lg" 
              variant="secondary"
              onClick={() => window.location.href = '/solutions/automation'}
            >
              Learn About ATD
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hero;