import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { FileText, BookOpen, Calculator } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CTASection } from '@/components/ui/cta-section';

const ResourcesOverview = () => {
  const resources = [
    {
      icon: <BookOpen className="w-12 h-12 text-[#1BC595]" />,
      title: "Blog",
      description: "Expert insights on enterprise IT management and automation",
      features: [
        "Technical Deep Dives",
        "Industry Trends",
        "Best Practices"
      ],
      link: "/resources/blog"
    },
    {
      icon: <FileText className="w-12 h-12 text-[#1BC595]" />,
      title: "Case Studies",
      description: "Real-world success stories from our enterprise clients",
      features: [
        "Implementation Stories",
        "ROI Analysis",
        "Client Testimonials"
      ],
      link: "/resources/case-studies"
    },
    {
      icon: <Calculator className="w-12 h-12 text-[#1BC595]" />,
      title: "ROI Tools",
      description: "Calculate your potential savings with our solutions",
      features: [
        "Cost Calculators",
        "Efficiency Metrics",
        "Comparison Tools"
      ],
      link: "/resources/roi-tools"
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Resources"
          description="Insights, guides, and tools to help you succeed"
        />
        
        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto mt-12">
          {resources.map((resource, index) => (
            <div 
              key={index}
              className="bg-card p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300 flex flex-col"
            >
              <div className="flex justify-center mb-6">
                {resource.icon}
              </div>
              <h3 className="text-xl font-semibold text-center mb-4 text-foreground">
                {resource.title}
              </h3>
              <p className="text-muted-foreground text-center mb-6">
                {resource.description}
              </p>
              <ul className="space-y-2 mb-8">
                {resource.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center text-muted-foreground">
                    <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>
              <Button 
                className="mt-auto bg-[#1BC595] hover:bg-[#1BC595]/90"
                onClick={() => window.location.href = resource.link}
              >
                Learn More
              </Button>
            </div>
          ))}
        </div>
      </div>

      <CTASection
        title="Need More Information?"
        description="Contact us to learn more about our solutions and services"
      />
    </div>
  );
};

export default ResourcesOverview;