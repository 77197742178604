import React from 'react';
import { Helmet } from 'react-helmet-async';
import branding from '@/lib/config/branding.json';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string[];
  schema?: object;
  canonical?: string;
  ogImage?: string;
}

export const SEO: React.FC<SEOProps> = ({
  title = branding.global.seo.title,
  description = branding.global.seo.description,
  keywords = branding.global.seo.keywords,
  schema = branding.global.seo.schema,
  canonical,
  ogImage = branding.global.seo.social.ogImage,
}) => {
  const metaKeywords = keywords.join(', ');
  
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={metaKeywords} />
      
      {/* Canonical URL */}
      {canonical && <link rel="canonical" href={canonical} />}
      
      {/* Open Graph */}
      <meta property="og:title" content={branding.global.seo.social.ogTitle} />
      <meta property="og:description" content={branding.global.seo.social.ogDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={ogImage} />
      
      {/* Twitter */}
      <meta name="twitter:card" content={branding.global.seo.social.twitterCard} />
      <meta name="twitter:creator" content={branding.global.seo.social.twitterCreator} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
      
      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};
