import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { CTASection } from '@/components/ui/cta-section';
import { Cog, Zap, Shield, BarChart, Bot, RefreshCw } from 'lucide-react';

const SCCMPage = () => {
  const features = [
    {
      icon: <Bot className="w-12 h-12 text-[#1BC595]" />,
      title: "Intelligent Automation",
      description: "Advanced automation capabilities for SCCM/MECM tasks",
      items: [
        "Automated inventory collection",
        "Smart deployment scheduling",
        "Patch management automation",
        "Configuration drift detection"
      ]
    },
    {
      icon: <Zap className="w-12 h-12 text-[#1BC595]" />,
      title: "Performance Optimization",
      description: "Enhance SCCM performance and efficiency",
      items: [
        "Resource utilization optimization",
        "Database performance tuning",
        "Network bandwidth management",
        "Client health monitoring"
      ]
    },
    {
      icon: <Shield className="w-12 h-12 text-[#1BC595]" />,
      title: "Security & Compliance",
      description: "Strengthen security posture and ensure compliance",
      items: [
        "Security baseline automation",
        "Compliance reporting",
        "Audit trail management",
        "Access control enhancement"
      ]
    }
  ];

  const benefits = [
    {
      icon: <Cog className="w-8 h-8 text-[#1BC595]" />,
      title: "Operational Efficiency",
      description: "Reduce manual tasks by up to 85% through intelligent automation"
    },
    {
      icon: <BarChart className="w-8 h-8 text-[#1BC595]" />,
      title: "Enhanced Visibility",
      description: "Comprehensive dashboards and reporting for better decision-making"
    },
    {
      icon: <RefreshCw className="w-8 h-8 text-[#1BC595]" />,
      title: "Continuous Optimization",
      description: "AI-driven insights for ongoing system improvements"
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="SCCM Enhancements"
          description="Advanced automation and optimization solutions for SCCM/MECM environments"
        />
        
        {/* Key Features */}
        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto mt-12">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-card p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300 hover:-translate-y-1 hover:shadow-lg hover:shadow-[#1BC595]/10"
            >
              <div className="flex justify-center mb-6">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold text-center mb-4 text-foreground">
                {feature.title}
              </h3>
              <p className="text-muted-foreground text-center mb-6">
                {feature.description}
              </p>
              <ul className="space-y-2">
                {feature.items.map((item, idx) => (
                  <li key={idx} className="flex items-center text-muted-foreground">
                    <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Overview Section */}
        <div className="max-w-4xl mx-auto mt-20">
          <div className="bg-card p-8 rounded-xl border border-border">
            <h2 className="text-2xl font-semibold mb-6 text-[#1BC595] text-center">
              Transform Your SCCM Environment
            </h2>
            <p className="text-lg text-muted-foreground leading-relaxed mb-8">
              Our SCCM enhancement solutions leverage advanced automation and AI to streamline 
              your system management operations. By automating routine tasks, optimizing 
              performance, and providing enhanced visibility, we help you maximize the value 
              of your SCCM investment.
            </p>
            <div className="grid md:grid-cols-3 gap-6">
              {benefits.map((benefit, index) => (
                <div key={index} className="text-center">
                  <div className="flex justify-center mb-4">
                    {benefit.icon}
                  </div>
                  <h3 className="font-semibold mb-2 text-foreground">
                    {benefit.title}
                  </h3>
                  <p className="text-sm text-muted-foreground">
                    {benefit.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Integration Capabilities */}
        <div className="max-w-4xl mx-auto mt-20 bg-card p-8 rounded-xl border border-border">
          <h2 className="text-2xl font-semibold mb-6 text-[#1BC595] text-center">
            Seamless Integration
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4 text-foreground">
                Enterprise Systems
              </h3>
              <ul className="space-y-2">
                <li className="flex items-center text-muted-foreground">
                  <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                  Active Directory integration
                </li>
                <li className="flex items-center text-muted-foreground">
                  <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                  Azure services compatibility
                </li>
                <li className="flex items-center text-muted-foreground">
                  <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                  Third-party security tools
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4 text-foreground">
                Automation Tools
              </h3>
              <ul className="space-y-2">
                <li className="flex items-center text-muted-foreground">
                  <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                  PowerShell integration
                </li>
                <li className="flex items-center text-muted-foreground">
                  <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                  REST API support
                </li>
                <li className="flex items-center text-muted-foreground">
                  <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                  Custom workflow engines
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <CTASection
        title="Ready to Enhance Your SCCM Environment?"
        description="Let's discuss how we can optimize your SCCM operations"
      />
    </div>
  );
};

export default SCCMPage;
export { SCCMPage };