import React, { FC } from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { CTASection } from '@/components/ui/cta-section';
import { Bot, Workflow, Zap, Code, Settings, LineChart } from 'lucide-react';

export const WorkflowsPage: FC = () => {
  const features = [
    {
      icon: <Bot className="w-12 h-12 text-[#1BC595]" />,
      title: "AI-Powered Automation",
      description: "Intelligent workflows that learn and adapt",
      items: [
        "Pattern recognition and optimization",
        "Predictive maintenance",
        "Automated decision-making",
        "Self-healing processes"
      ]
    },
    {
      icon: <Workflow className="w-12 h-12 text-[#1BC595]" />,
      title: "Custom Workflow Design",
      description: "Tailored solutions for your unique needs",
      items: [
        "Process analysis and mapping",
        "Custom workflow development",
        "Integration design",
        "Performance optimization"
      ]
    },
    {
      icon: <Zap className="w-12 h-12 text-[#1BC595]" />,
      title: "Rapid Implementation",
      description: "Quick deployment with immediate results",
      items: [
        "Agile development methodology",
        "Phased rollout approach",
        "Continuous integration",
        "Real-time monitoring"
      ]
    }
  ];

  const capabilities = [
    {
      icon: <Code className="w-8 h-8 text-[#1BC595]" />,
      title: "Advanced Integration",
      description: "Seamless connection with existing systems and tools"
    },
    {
      icon: <Settings className="w-8 h-8 text-[#1BC595]" />,
      title: "Process Automation",
      description: "End-to-end automation of complex workflows"
    },
    {
      icon: <LineChart className="w-8 h-8 text-[#1BC595]" />,
      title: "Performance Analytics",
      description: "Real-time monitoring and optimization"
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Custom AI Workflows"
          description="Intelligent automation solutions tailored to your enterprise needs"
        />
        
        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto mt-12">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-card p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300 hover:-translate-y-1 hover:shadow-lg hover:shadow-[#1BC595]/10"
            >
              <div className="flex justify-center mb-6">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold text-center mb-4 text-foreground">
                {feature.title}
              </h3>
              <p className="text-muted-foreground text-center mb-6">
                {feature.description}
              </p>
              <ul className="space-y-2">
                {feature.items.map((item, idx) => (
                  <li key={idx} className="flex items-center text-muted-foreground">
                    <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="max-w-4xl mx-auto mt-20">
          <h2 className="text-2xl font-semibold mb-12 text-[#1BC595] text-center">
            Core Capabilities
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {capabilities.map((capability, index) => (
              <div key={index} className="bg-card p-6 rounded-xl border border-border text-center">
                <div className="flex justify-center mb-4">
                  {capability.icon}
                </div>
                <h3 className="font-semibold mb-2 text-foreground">
                  {capability.title}
                </h3>
                <p className="text-sm text-muted-foreground">
                  {capability.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <CTASection
        title="Ready to Automate Your Workflows?"
        description="Let's discuss how our AI-powered automation can transform your operations"
      />
    </div>
  );
};