import React from 'react';
import { cn } from '@/lib/utils';

interface SectionHeaderProps {
  title: string;
  description?: string;
  className?: string;
  gradient?: boolean;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  description,
  className,
  gradient = false,
}) => {
  return (
    <div className={cn('text-center mb-16', className)}>
      <h2 
        className={cn(
          'text-4xl font-bold mb-6 text-white',
          gradient && 'gradient-text'
        )}
      >
        {title}
      </h2>
      {description && (
        <p className="text-xl text-muted-foreground max-w-3xl mx-auto">
          {description}
        </p>
      )}
    </div>
  );
};