import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { Database, Cog, Bot } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CTASection } from '@/components/ui/cta-section';

const SolutionsOverview = () => {
  const solutions = [
    {
      icon: <Database className="w-12 h-12 text-[#1BC595]" />,
      title: "ATD: Air-Gap Transfer Device",
      description: "Complete asset tracking and compliance for air-gapped environments",
      features: [
        "Hardware & Software Inventory",
        "Security Baseline Management",
        "SCCM/MECM Integration"
      ],
      link: "/solutions/atd"
    },
    {
      icon: <Cog className="w-12 h-12 text-[#1BC595]" />,
      title: "SCCM Enhancements",
      description: "Advanced automation and optimization for SCCM/MECM environments",
      features: [
        "Custom Automation Solutions",
        "Performance Optimization",
        "Enhanced Reporting"
      ],
      link: "/solutions/sccm"
    },
    {
      icon: <Bot className="w-12 h-12 text-[#1BC595]" />,
      title: "Enterprise IT Automation",
      description: "Intelligent automation for complex enterprise environments",
      features: [
        "AI-Driven Workflows",
        "Process Optimization",
        "Custom Integrations"
      ],
      link: "/solutions/automation"
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Our Solutions"
          description="Comprehensive software and automation solutions for enterprise excellence"
        />
        
        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto mt-12">
          {solutions.map((solution, index) => (
            <div 
              key={index}
              className="bg-card p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300 flex flex-col"
            >
              <div className="flex justify-center mb-6">
                {solution.icon}
              </div>
              <h3 className="text-xl font-semibold text-center mb-4 text-foreground">
                {solution.title}
              </h3>
              <p className="text-muted-foreground text-center mb-6">
                {solution.description}
              </p>
              <ul className="space-y-2 mb-8">
                {solution.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center text-muted-foreground">
                    <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>
              <Button 
                className="mt-auto bg-[#1BC595] hover:bg-[#1BC595]/90"
                onClick={() => window.location.href = solution.link}
              >
                Learn More
              </Button>
            </div>
          ))}
        </div>
      </div>

      <CTASection
        title="Ready to Transform Your IT Operations?"
        description="Let's discuss how our solutions can help optimize your enterprise"
      />
    </div>
  );
};

export default SolutionsOverview;