import { FREQUENCY_MAP, TRADITIONAL_HOURS, MODERN_HOURS } from './constants';
import type { CalculationResult } from './types';

export const formatCurrency = (amount: number): string => 
  `$${(amount/1000).toFixed(1)}k`;

export const calculateSavings = (
  systems: number,
  techRate: number,
  frequency: keyof typeof FREQUENCY_MAP
): CalculationResult => {
  const cycles = FREQUENCY_MAP[frequency].times;
  
  // Traditional approach costs
  const traditionalCostPerCycle = {
    low: systems * (TRADITIONAL_HOURS.min * techRate),
    high: systems * (TRADITIONAL_HOURS.max * techRate)
  };
  
  // Modern solution costs
  const modernCostPerCycle = {
    low: systems * (MODERN_HOURS.min * techRate),
    high: systems * (MODERN_HOURS.max * techRate)
  };
  
  // Annual costs
  const traditionalAnnualCost = {
    low: traditionalCostPerCycle.low * cycles,
    high: traditionalCostPerCycle.high * cycles
  };
  
  const modernAnnualCost = {
    low: modernCostPerCycle.low * cycles,
    high: modernCostPerCycle.high * cycles
  };
  
  return {
    perCycle: {
      traditional: `${formatCurrency(traditionalCostPerCycle.low)} - ${formatCurrency(traditionalCostPerCycle.high)}`,
      modern: `${formatCurrency(modernCostPerCycle.low)} - ${formatCurrency(modernCostPerCycle.high)}`,
    },
    annual: {
      traditional: `${formatCurrency(traditionalAnnualCost.low)} - ${formatCurrency(traditionalAnnualCost.high)}`,
      modern: `${formatCurrency(modernAnnualCost.low)} - ${formatCurrency(modernAnnualCost.high)}`,
      savings: `${formatCurrency(traditionalAnnualCost.low - modernAnnualCost.high)} - ${formatCurrency(traditionalAnnualCost.high - modernAnnualCost.low)}`
    },
    metrics: {
      hoursPerYear: {
        traditional: {
          low: TRADITIONAL_HOURS.min * systems * cycles,
          high: TRADITIONAL_HOURS.max * systems * cycles
        },
        modern: {
          low: MODERN_HOURS.min * systems * cycles,
          high: MODERN_HOURS.max * systems * cycles
        }
      }
    }
  };
};