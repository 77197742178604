import React from 'react';
import { Database, Cog, Bot, Code } from 'lucide-react';
import { Button } from '../ui/button';
import { SectionHeader } from '../ui/section-header';

const Solutions = () => {
  const solutions = [
    {
      icon: <Database className="w-12 h-12 text-[#1BC595]" />,
      title: "ATD: Air-Gap Transfer Device",
      description: "Secure automation for air-gapped systems",
      features: [
        "Secure Inventory Collection",
        "Automated Patch Management",
        "Compliance Reporting"
      ],
      link: "/solutions/atd"
    },
    {
      icon: <Cog className="w-12 h-12 text-[#1BC595]" />,
      title: "SCCM Automation Suite",
      description: "Enhanced SCCM/MECM management tools",
      features: [
        "Asset Tracking Automation",
        "Workflow Optimization",
        "Health Monitoring Tools"
      ],
      link: "/solutions/sccm"
    },
    {
      icon: <Bot className="w-12 h-12 text-[#1BC595]" />,
      title: "Systems Management Tools",
      description: "Custom automation for enterprise IT",
      features: [
        "Compliance Automation",
        "Custom Scripts & Tools",
        "Process Optimization"
      ],
      link: "/solutions/automation"
    }
  ];

  return (
    <section className="py-20 bg-card" id="solutions">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Our Solutions"
          description="Advanced automation tools for enterprise systems management"
        />
        
        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {solutions.map((solution, index) => (
            <div 
              key={index}
              className="bg-background p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300 flex flex-col"
            >
              <div className="flex justify-center mb-6">
                {solution.icon}
              </div>
              <h3 className="text-xl font-semibold text-center mb-4 text-foreground">
                {solution.title}
              </h3>
              <p className="text-muted-foreground text-center mb-6">
                {solution.description}
              </p>
              <ul className="space-y-2 mb-8">
                {solution.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center text-muted-foreground">
                    <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>
              <Button 
                className="mt-auto bg-[#1BC595] hover:bg-[#1BC595]/90"
                onClick={() => window.location.href = solution.link}
              >
                Learn More
              </Button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Solutions;