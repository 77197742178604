import React from 'react';
import { Database, Server, Shield, RefreshCw } from 'lucide-react';

const ArchitectureDiagram = () => {
  return (
    <div className="relative bg-background p-8 rounded-xl border border-border">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Source System */}
        <div className="flex flex-col items-center text-center">
          <div className="bg-card p-4 rounded-full mb-4 border border-border">
            <Server className="w-12 h-12 text-[#1BC595]" />
          </div>
          <h4 className="text-lg font-semibold mb-2 text-foreground">Source System</h4>
          <p className="text-sm text-muted-foreground">SCCM/MECM Server</p>
        </div>

        {/* ATD */}
        <div className="flex flex-col items-center text-center">
          <div className="bg-card p-4 rounded-full mb-4 border border-border">
            <Shield className="w-12 h-12 text-[#1BC595]" />
          </div>
          <h4 className="text-lg font-semibold mb-2 text-foreground">ATD Solution</h4>
          <p className="text-sm text-muted-foreground">Secure Transport Medium</p>
        </div>

        {/* Target System */}
        <div className="flex flex-col items-center text-center">
          <div className="bg-card p-4 rounded-full mb-4 border border-border">
            <Database className="w-12 h-12 text-[#1BC595]" />
          </div>
          <h4 className="text-lg font-semibold mb-2 text-foreground">Target System</h4>
          <p className="text-sm text-muted-foreground">Air-Gapped Environment</p>
        </div>
      </div>

      {/* Connection Lines */}
      <div className="hidden md:block absolute top-1/2 left-1/3 right-1/3 transform -translate-y-1/2">
        <div className="flex items-center justify-center space-x-4">
          <div className="h-0.5 flex-1 bg-border"></div>
          <RefreshCw className="w-6 h-6 text-[#1BC595]" />
          <div className="h-0.5 flex-1 bg-border"></div>
        </div>
      </div>
    </div>
  );
};

export default ArchitectureDiagram;