import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { CTASection } from '@/components/ui/cta-section';
import { Shield, Code, Workflow, Bot, Cog, Network, Users, Award, Globe } from 'lucide-react';

const AboutPage = () => {
  const stats = [
    {
      number: "500+",
      label: "Enterprise Clients",
      description: "Trusted by leading organizations worldwide"
    },
    {
      number: "25+",
      label: "Years Experience",
      description: "Deep expertise in enterprise IT solutions"
    },
    {
      number: "99.9%",
      label: "Client Retention",
      description: "Long-term partnerships built on trust"
    }
  ];

  const values = [
    {
      icon: <Shield className="w-8 h-8 text-[#1BC595]" />,
      title: "Security First",
      description: "We prioritize the security and integrity of your systems in everything we do"
    },
    {
      icon: <Users className="w-8 h-8 text-[#1BC595]" />,
      title: "Client Partnership",
      description: "Building lasting relationships through collaboration and trust"
    },
    {
      icon: <Award className="w-8 h-8 text-[#1BC595]" />,
      title: "Excellence",
      description: "Delivering exceptional quality and innovative solutions"
    }
  ];

  const expertise = [
    {
      icon: <Code className="w-12 h-12 text-[#1BC595]" />,
      title: "Enterprise Solutions",
      description: "Comprehensive software solutions for complex IT environments",
      achievements: [
        "500+ successful implementations",
        "Industry-leading security features",
        "Seamless integration capabilities"
      ]
    },
    {
      icon: <Bot className="w-12 h-12 text-[#1BC595]" />,
      title: "AI & Automation",
      description: "Cutting-edge automation powered by artificial intelligence",
      achievements: [
        "85% reduction in manual tasks",
        "Advanced predictive analytics",
        "Custom AI workflows"
      ]
    },
    {
      icon: <Globe className="w-12 h-12 text-[#1BC595]" />,
      title: "Global Reach",
      description: "Supporting enterprises worldwide with local expertise",
      achievements: [
        "24/7 global support",
        "Multi-language capabilities",
        "Regional compliance expertise"
      ]
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-background relative overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 text-foreground">
              Transforming Enterprise IT Through{' '}
              <span className="text-[#1BC595]">Innovation</span>
            </h1>
            <p className="text-xl text-muted-foreground mb-12 leading-relaxed">
              At LevelLogik, we combine decades of experience with cutting-edge technology 
              to deliver transformative solutions for modern enterprises.
            </p>
            
            <div className="grid md:grid-cols-3 gap-8 mt-16">
              {stats.map((stat, index) => (
                <div key={index} className="bg-card p-6 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300">
                  <div className="text-3xl font-bold text-[#1BC595] mb-2">{stat.number}</div>
                  <div className="text-lg font-semibold text-foreground mb-2">{stat.label}</div>
                  <p className="text-sm text-muted-foreground">{stat.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-20 bg-card">
        <div className="container mx-auto px-4">
          <SectionHeader
            title="Our Core Values"
            description="Principles that guide everything we do"
          />
          
          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {values.map((value, index) => (
              <div key={index} className="bg-background p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300">
                <div className="flex justify-center mb-6">
                  {value.icon}
                </div>
                <h3 className="text-xl font-semibold text-center mb-4 text-foreground">
                  {value.title}
                </h3>
                <p className="text-muted-foreground text-center">
                  {value.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Expertise Section */}
      <section className="py-20 bg-background">
        <div className="container mx-auto px-4">
          <SectionHeader
            title="Our Expertise"
            description="Comprehensive solutions for modern enterprises"
          />
          
          <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {expertise.map((item, index) => (
              <div key={index} className="bg-card p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300 hover:-translate-y-1 hover:shadow-lg hover:shadow-[#1BC595]/10">
                <div className="flex justify-center mb-6">
                  {item.icon}
                </div>
                <h3 className="text-xl font-semibold text-center mb-4 text-foreground">
                  {item.title}
                </h3>
                <p className="text-muted-foreground text-center mb-6">
                  {item.description}
                </p>
                <ul className="space-y-2">
                  {item.achievements.map((achievement, idx) => (
                    <li key={idx} className="flex items-center text-muted-foreground">
                      <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                      {achievement}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      <CTASection
        title="Ready to Transform Your Enterprise?"
        description="Let's discuss how we can help optimize your operations"
      />
    </div>
  );
};

export default AboutPage;