import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';

export const TermsPage = () => {
  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Terms & Conditions"
          description="Last updated: December 13, 2023"
        />
        
        <div className="prose prose-lg max-w-none mt-8">
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p>By accessing and using LevelLogik's services, you accept and agree to be bound by these Terms and Conditions and our Privacy Policy.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Use License</h2>
            <p>Permission is granted to temporarily access and use our services for personal, non-commercial purposes. This license does not include:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Modifying or copying our materials</li>
              <li>Using materials for commercial purposes</li>
              <li>Attempting to reverse engineer our software</li>
              <li>Removing any copyright or proprietary notations</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. Service Description</h2>
            <p>LevelLogik provides secure solutions for air-gapped systems management and compliance. Our services include:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>System management tools</li>
              <li>Compliance monitoring</li>
              <li>Security assessments</li>
              <li>Technical support</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. User Obligations</h2>
            <p>Users of our services agree to:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Provide accurate information</li>
              <li>Maintain security of account credentials</li>
              <li>Comply with applicable laws and regulations</li>
              <li>Report any security vulnerabilities</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
            <p>LevelLogik shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use our services.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Changes to Terms</h2>
            <p>We reserve the right to modify these terms at any time. We will notify users of any material changes via email or through our services.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. Contact Information</h2>
            <p>For questions about these Terms & Conditions, please contact us at:</p>
            <ul className="list-none mt-2">
              <li>Email: legal@levellogik.com</li>
              <li>Phone: 1-800-LEVELLOGIK</li>
              <li>Address: 123 Secure Way, Innovation City, USA</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;