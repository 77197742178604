import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from './components/ui/theme-provider';
import { SEO } from '@/components/seo/SEO';
import { Navbar, Footer } from './components/layout';
import { HomePage } from '@/pages/home';
import { AboutPage, LeadershipPage, MissionPage } from '@/pages/about';
import { ResourcesPage, BlogPage, CaseStudiesPage, ROIToolsPage } from '@/pages/resources';
import { SolutionsPage } from '@/pages/solutions';
import { AirGapTransferPage } from '@/pages/solutions/air-gap-transfer-solution';
import { SCCMPage } from '@/pages/solutions/sccm';
import { AutomationPage } from '@/pages/solutions/automation';
import { WorkflowsPage } from '@/pages/solutions/automation/workflows';
import { PrivacyPage, TermsPage } from '@/pages/legal';
import { ContactPage } from './pages/contact';
import { ROUTES } from './lib/constants/routes';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <ThemeProvider defaultTheme="dark" storageKey="levellogik-theme">
        <SEO />
        <Router>
          <div className="min-h-screen bg-background text-foreground">
            <Navbar />
            <main className="flex-1">
              <Routes>
                <Route path={ROUTES.HOME} element={<HomePage />} />
                <Route path={ROUTES.ABOUT.OVERVIEW} element={<AboutPage />} />
                <Route path={ROUTES.ABOUT.LEADERSHIP} element={<LeadershipPage />} />
                <Route path={ROUTES.ABOUT.MISSION} element={<MissionPage />} />
                <Route path={ROUTES.RESOURCES.ROOT} element={<ResourcesPage />} />
                <Route path={ROUTES.RESOURCES.BLOG} element={<BlogPage />} />
                <Route path={ROUTES.RESOURCES.CASE_STUDIES} element={<CaseStudiesPage />} />
                <Route path={ROUTES.RESOURCES.ROI} element={<ROIToolsPage />} />
                <Route path={ROUTES.SOLUTIONS} element={<SolutionsPage />} />
                <Route path={ROUTES.SOFTWARE.ATD} element={<AirGapTransferPage />} />
                <Route path={ROUTES.SOFTWARE.SCCM} element={<SCCMPage />} />
                <Route path={ROUTES.AUTOMATION.ROOT} element={<AutomationPage />} />
                <Route path={ROUTES.AUTOMATION.WORKFLOWS} element={<WorkflowsPage />} />
                <Route path={ROUTES.LEGAL.PRIVACY} element={<PrivacyPage />} />
                <Route path={ROUTES.LEGAL.TERMS} element={<TermsPage />} />
                <Route path={ROUTES.CONTACT} element={<ContactPage />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;