import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';

export const PrivacyPage = () => {
  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Privacy Policy"
          description="Last updated: December 13, 2023"
        />
        
        <div className="prose prose-lg max-w-none mt-8">
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
            <p>At LevelLogik, we collect information to provide better services to our users. This includes:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Information you provide to us directly</li>
              <li>Information we collect from your use of our services</li>
              <li>Information from third-party sources</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Provide and maintain our services</li>
              <li>Improve and develop new features</li>
              <li>Protect against fraud and abuse</li>
              <li>Communicate with you about our services</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. Information Sharing</h2>
            <p>We do not share your personal information except in the following limited circumstances:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>With your consent</li>
              <li>For legal reasons</li>
              <li>With our trusted service providers</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
            <p>We implement appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your information.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
            <p>You have certain rights regarding your personal information, including:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>The right to access your data</li>
              <li>The right to correct inaccurate data</li>
              <li>The right to delete your data</li>
              <li>The right to object to processing</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
            <ul className="list-none mt-2">
              <li>Email: privacy@levellogik.com</li>
              <li>Phone: 1-800-LEVELLOGIK</li>
              <li>Address: 123 Secure Way, Innovation City, USA</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;