export interface WebhookConfig {
  url: string;
  method: 'POST' | 'GET' | 'PUT' | 'DELETE';
  headers: Record<string, string>;
}

export interface ApiConfig {
  webhooks: {
    contact: WebhookConfig;
  };
}

export interface EnvironmentConfig {
  name: 'development' | 'staging' | 'production';
}

export interface Config {
  api: ApiConfig;
  environment: EnvironmentConfig;
}

// Helper function to type check the config file
export function validateConfig(config: unknown): config is Config {
  const conf = config as Config;
  return (
    typeof conf === 'object' &&
    conf !== null &&
    typeof conf.api === 'object' &&
    typeof conf.api.webhooks === 'object' &&
    typeof conf.api.webhooks.contact === 'object' &&
    typeof conf.api.webhooks.contact.url === 'string' &&
    typeof conf.api.webhooks.contact.method === 'string' &&
    typeof conf.api.webhooks.contact.headers === 'object'
  );
}
