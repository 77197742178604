import React from 'react';
import { BackToTop } from '@/components/layout';
import {
  Hero,
  Solutions,
  Features,
  HowItWorks,
  Testimonials
} from '@/components/sections';
import { CTASection } from '@/components/ui/cta-section';

export const HomePage = () => {
  return (
    <>
      <Hero />
      <Solutions />
      <Features />
      <HowItWorks />
      <Testimonials />
      <CTASection />
      <BackToTop />
    </>
  );
};