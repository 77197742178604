import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
 
export const scrollToSection = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
  e.preventDefault();
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}