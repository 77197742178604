import React from 'react';
import { SectionHeader } from '../../ui/section-header';
import ArchitectureDiagram from './ArchitectureDiagram';
import SecurityFeatures from './SecurityFeatures';
import ComplianceStandards from './ComplianceStandards';
import { CheckCircle } from 'lucide-react';

const TechnicalOverview = () => {
  return (
    <section className="py-20 bg-background" id="technical">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="ATD Technical Overview"
          description="Our Air-Gap Transfer Device ensures secure and efficient management of air-gapped systems through advanced architecture and robust security features."
        />

        <div className="space-y-16">
          <div>
            <h3 className="text-2xl font-semibold text-center mb-8 text-primary">
              ATD Architecture
            </h3>
            <ArchitectureDiagram />
          </div>

          <div>
            <h3 className="text-2xl font-semibold text-center mb-8 text-primary">
              Security Features
            </h3>
            <SecurityFeatures />
          </div>

          <div className="grid md:grid-cols-2 gap-8 bg-background rounded-xl p-8 border border-border">
            <ComplianceStandards />
            <div className="bg-card p-6 rounded-lg border border-border">
              <h4 className="text-xl font-semibold mb-6 text-primary">ATD Core Capabilities</h4>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-primary rounded-full mr-3"></span>
                  <span className="text-muted-foreground">Secure air-gapped data transfer</span>
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-primary rounded-full mr-3"></span>
                  <span className="text-muted-foreground">SCCM/MECM integration</span>
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-primary rounded-full mr-3"></span>
                  <span className="text-muted-foreground">Automated inventory management</span>
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-primary rounded-full mr-3"></span>
                  <span className="text-muted-foreground">Secure update distribution</span>
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-primary rounded-full mr-3"></span>
                  <span className="text-muted-foreground">Compliance monitoring and reporting</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnicalOverview;