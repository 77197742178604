import React from 'react';
import { Lightbulb, Cog, Zap, ArrowRight } from 'lucide-react';
import { SectionHeader } from '../ui/section-header';

const HowItWorks = () => {
  const steps = [
    {
      icon: <Lightbulb className="w-12 h-12 text-[#1BC595]" />,
      title: "Assessment & Planning",
      description: "We analyze your systems management needs and design automation solutions",
      details: [
        "SCCM environment analysis",
        "Air-gapped systems assessment",
        "Automation requirements",
        "Security compliance planning"
      ]
    },
    {
      icon: <Cog className="w-12 h-12 text-[#1BC595]" />,
      title: "Implementation",
      description: "Secure deployment of automation solutions",
      details: [
        "SCCM automation setup",
        "Air-gapped systems integration",
        "Security configuration",
        "Compliance validation"
      ]
    },
    {
      icon: <Zap className="w-12 h-12 text-[#1BC595]" />,
      title: "Optimization & Support",
      description: "Continuous improvement of automation workflows",
      details: [
        "Process optimization",
        "Performance monitoring",
        "Security updates",
        "Ongoing support"
      ]
    }
  ];

  return (
    <section className="py-20 bg-card" id="how-it-works">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Our Approach"
          description="Expert implementation of systems management automation"
          gradient
        />
        
        <div className="grid md:grid-cols-3 gap-12">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              <div className="flex flex-col h-full bg-background rounded-xl border border-border p-8 hover:border-[#1BC595]/50 transition-all duration-300 shadow-lg group hover:shadow-[#1BC595]/20 hover:-translate-y-1">
                <div className="bg-card p-4 rounded-full mb-6 border border-border w-fit group-hover:border-[#1BC595]/50 transition-colors">
                  {step.icon}
                </div>
                <h3 className="text-xl font-semibold mb-4 text-foreground">
                  {step.title}
                </h3>
                <p className="text-muted-foreground mb-6">
                  {step.description}
                </p>
                <ul className="space-y-3 mt-auto">
                  {step.details.map((detail, idx) => (
                    <li key={idx} className="flex items-center text-sm text-muted-foreground">
                      <ArrowRight className="w-4 h-4 mr-2 text-[#1BC595] transition-transform group-hover:translate-x-1" />
                      {detail}
                    </li>
                  ))}
                </ul>
              </div>
              {index < steps.length - 1 && (
                <div className="hidden md:block absolute top-1/2 left-full w-12 border-t-2 border-dashed border-[#1BC595]/20 -translate-x-6 transform" />
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;