import React from 'react';
import { ThemeToggle } from '../ui/theme-toggle';
import { Button } from '../ui/button';
import { Menu } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/lib/constants/routes';
import branding from '../../lib/config/branding.json';

type NavItem = {
  href: string;
  label: string;
};

type SubmenuItem = NavItem | {
  label: string;
  items: NavItem[];
};

type NavLink = NavItem & {
  submenu?: SubmenuItem[];
};

const Navbar = () => {
  const { branding: brandingConfig } = branding.global;
  
  const navLinks: NavLink[] = [
    {
      href: ROUTES.SOLUTIONS,
      label: 'Solutions',
      submenu: [
        { href: ROUTES.SOFTWARE.ATD, label: 'Air-Gap Transfer Solution' },
        { href: ROUTES.SOFTWARE.SCCM, label: 'SCCM Enhancements' }
      ]
    },
    {
      href: ROUTES.AUTOMATION.ROOT,
      label: 'Services',
      submenu: [
        { href: ROUTES.AUTOMATION.WORKFLOWS, label: 'Custom AI Workflows' },
        { href: ROUTES.AUTOMATION.ROOT, label: 'Automation Services' }
      ]
    },
    {
      href: ROUTES.RESOURCES.ROOT,
      label: 'Resources',
      submenu: [
        { href: ROUTES.RESOURCES.BLOG, label: 'Blog' },
        { href: ROUTES.RESOURCES.CASE_STUDIES, label: 'Case Studies' },
        { href: ROUTES.RESOURCES.ROI, label: 'ROI Calculator' }
      ]
    },
    {
      href: ROUTES.ABOUT.OVERVIEW,
      label: 'About Us',
      submenu: [
        { href: ROUTES.ABOUT.OVERVIEW, label: 'Company Overview' },
        { href: ROUTES.ABOUT.LEADERSHIP, label: 'Leadership Team' },
        { href: ROUTES.ABOUT.MISSION, label: 'Mission & Values' }
      ]
    }
  ];

  return (
    <nav className="fixed top-0 w-full z-50 border-b border-border bg-background/80 backdrop-blur-sm">
      <div className="container mx-auto px-4">
        <div className="flex h-16 items-center justify-between">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to={ROUTES.HOME} className="flex items-center gap-2">
              <img src={brandingConfig.logo.path} alt={brandingConfig.logo.alt} className="h-8 w-8" />
              <span className="font-bold text-xl">LevelLogik</span>
            </Link>
          </div>
          
          {/* Centered Navigation */}
          <div className="hidden md:flex flex-1 justify-center">
            <div className="flex items-center gap-8">
              {navLinks.map((link) => (
                <div key={link.href} className="relative group">
                  <Link
                    to={link.href}
                    className="text-muted-foreground hover:text-foreground transition-colors"
                  >
                    {link.label}
                  </Link>
                  
                  {link.submenu && (
                    <div className="absolute left-1/2 -translate-x-1/2 mt-2 w-48 bg-background border border-border rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                      <div className="py-2">
                        {link.submenu.map((item) => (
                          <Link
                            key={item.href}
                            to={item.href}
                            className="block px-4 py-2 text-sm text-muted-foreground hover:text-foreground hover:bg-accent/50 transition-colors"
                          >
                            {item.label}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Right Side Actions */}
          <div className="flex items-center gap-4">
            <ThemeToggle />
            <div className="hidden md:block">
              <Button asChild>
                <Link to={ROUTES.CONTACT}>Contact Us</Link>
              </Button>
            </div>
            <Button variant="ghost" size="icon" className="md:hidden">
              <Menu className="h-5 w-5" />
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;