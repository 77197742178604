import React from 'react';
import { cn } from '@/lib/utils';
import type { IconFeature } from '@/lib/types/components';

interface FeatureCardProps extends IconFeature {
  className?: string;
  items?: string[];
}

export const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  items,
  className,
}) => {
  return (
    <div 
      className={cn(
        "bg-background p-6 rounded-xl border border-border hover:border-primary/50 transition-all duration-300 card-hover",
        className
      )}
    >
      <div className="flex justify-center mb-6">
        {icon}
      </div>
      <h3 className="text-xl font-semibold text-center mb-4 text-foreground">
        {title}
      </h3>
      {description && (
        <p className="text-muted-foreground text-center mb-4">
          {description}
        </p>
      )}
      {items && (
        <ul className="space-y-2">
          {items.map((item, idx) => (
            <li key={idx} className="flex items-center text-muted-foreground">
              <span className="w-1.5 h-1.5 bg-primary rounded-full mr-2" />
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};