import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { CTASection } from '@/components/ui/cta-section';

const BlogPage = () => {
  const posts = [
    {
      title: "The Future of Air-Gapped System Management",
      excerpt: "Explore how modern solutions are transforming secure environment management...",
      date: "March 15, 2024",
      category: "Technology"
    },
    // Additional blog posts can be added here
  ];

  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Latest Insights"
          description="Expert perspectives on enterprise IT management and automation"
        />
        
        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto mt-12">
          {posts.map((post, index) => (
            <div key={index} className="bg-card p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300">
              <div className="text-sm text-[#1BC595] mb-2">{post.category}</div>
              <h3 className="text-xl font-semibold mb-4 text-foreground">{post.title}</h3>
              <p className="text-muted-foreground mb-4">{post.excerpt}</p>
              <div className="text-sm text-muted-foreground">{post.date}</div>
            </div>
          ))}
        </div>
      </div>
      
      <CTASection
        title="Want More Insights?"
        description="Subscribe to our newsletter for the latest updates"
      />
    </div>
  );
};

export default BlogPage;