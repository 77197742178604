import React from 'react';
import type { ComparisonMetric } from '@/lib/types/components';

const ComparisonTable = () => {
  const data: ComparisonMetric[] = [
    {
      task: 'Initial Visit (Collection)',
      traditional: '75–125 minutes',
      modern: '5–10 minutes',
      improvement: '~92% faster'
    },
    {
      task: 'SCCM Processing',
      traditional: '60–95 minutes',
      modern: '15–20 minutes',
      improvement: '~80% faster'
    },
    {
      task: 'Return Visit (Updates)',
      traditional: '60–110 minutes',
      modern: '10–15 minutes',
      improvement: '~88% faster'
    },
    {
      task: 'Total Time/System',
      traditional: '3.25–5.4 hours',
      modern: '0.5–0.75 hours',
      improvement: '~85% faster',
      isTotal: true
    }
  ];

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="bg-primary/10 text-foreground">
            <th className="px-6 py-4 text-left">Task</th>
            <th className="px-6 py-4 text-left">Traditional Approach</th>
            <th className="px-6 py-4 text-left">With Our Solution</th>
            <th className="px-6 py-4 text-left">Improvement</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-border">
          {data.map((row, index) => (
            <tr 
              key={index}
              className={`${
                row.isTotal ? 'bg-primary/5 font-semibold' : 'bg-background'
              } hover:bg-muted/50`}
            >
              <td className="px-6 py-4 text-foreground">{row.task}</td>
              <td className="px-6 py-4 text-destructive">{row.traditional}</td>
              <td className="px-6 py-4 text-[#1BC595]">{row.modern}</td>
              <td className="px-6 py-4 text-[#1BC595]">{row.improvement}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;