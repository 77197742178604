import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './button';
import { SectionHeader } from './section-header';

interface CTASectionProps {
  title?: string;
  description?: string;
  className?: string;
}

export const CTASection = ({
  title = "Ready to Transform Your IT Operations?",
  description = "Take the first step towards more efficient and secure systems management",
  className = ""
}: CTASectionProps) => {
  const navigate = useNavigate();

  return (
    <section className={`py-20 bg-background ${className}`}>
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <SectionHeader
            title={title}
            description={description}
          />
          <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
            <Button 
              size="lg" 
              className="bg-[#1BC595] hover:bg-[#1BC595]/90"
              onClick={() => navigate('/contact')}
            >
              Contact Us Today
            </Button>
            <Button 
              size="lg" 
              variant="outline"
              onClick={() => navigate('/contact')}
            >
              Schedule a Demo
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};