import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { CTASection } from '@/components/ui/cta-section';
import { Building, Shield, Cpu } from 'lucide-react';

const CaseStudiesPage = () => {
  const studies = [
    {
      icon: <Shield className="w-8 h-8 text-[#1BC595]" />,
      title: "Defense Contractor Achieves 85% Time Savings",
      industry: "Defense",
      results: [
        "Reduced system management time by 85%",
        "Improved compliance reporting accuracy",
        "Enhanced security posture"
      ]
    },
    // Additional case studies can be added here
  ];

  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Client Success Stories"
          description="Real-world examples of our solutions in action"
        />
        
        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto mt-12">
          {studies.map((study, index) => (
            <div key={index} className="bg-card p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300">
              <div className="flex items-center mb-6">
                {study.icon}
                <div className="ml-4">
                  <div className="text-sm text-[#1BC595] mb-1">{study.industry}</div>
                  <h3 className="text-xl font-semibold text-foreground">{study.title}</h3>
                </div>
              </div>
              <div className="space-y-2">
                {study.results.map((result, idx) => (
                  <div key={idx} className="flex items-center text-muted-foreground">
                    <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                    {result}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <CTASection
        title="Ready to Write Your Success Story?"
        description="Let's discuss how we can help transform your operations"
      />
    </div>
  );
};

export default CaseStudiesPage;