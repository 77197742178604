import configData from './config.json';
import { Config, validateConfig } from './config.types';

if (!validateConfig(configData)) {
  throw new Error('Invalid config file structure');
}

export const config: Config = configData;

// Helper functions to get specific config values
export const getContactWebhookUrl = () => config.api.webhooks.contact.url;
export const getContactWebhookConfig = () => config.api.webhooks.contact;
