import React from 'react';
import { Bot, Shield, Cog, FileText, Code, Zap, ArrowRight } from 'lucide-react';
import { SectionHeader } from '../ui/section-header';

const Features = () => {
  const features = [
    {
      icon: <Bot className="w-12 h-12 text-[#1BC595]" />,
      title: "Custom Automation",
      items: [
        "Task and process automation",
        "Custom script development",
        "Workflow optimization"
      ]
    },
    {
      icon: <Shield className="w-12 h-12 text-[#1BC595]" />,
      title: "Secure Integration",
      items: [
        "SCCM/MECM automation",
        "Air-gapped systems support",
        "Secure data transfer"
      ]
    },
    {
      icon: <Cog className="w-12 h-12 text-[#1BC595]" />,
      title: "System Management",
      items: [
        "Asset tracking automation",
        "Compliance automation",
        "Configuration management"
      ]
    },
    {
      icon: <FileText className="w-12 h-12 text-[#1BC595]" />,
      title: "Reporting & Analytics",
      items: [
        "Automated reporting",
        "Performance metrics",
        "System health monitoring"
      ]
    },
    {
      icon: <Code className="w-12 h-12 text-[#1BC595]" />,
      title: "Development Services",
      items: [
        "Custom tool development",
        "Automation consulting",
        "Solution implementation"
      ]
    },
    {
      icon: <Zap className="w-12 h-12 text-[#1BC595]" />,
      title: "Efficiency Tools",
      items: [
        "Process streamlining",
        "Time-saving automation",
        "Resource optimization"
      ]
    }
  ];

  return (
    <section className="py-20 bg-background" id="features">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Automation Features"
          description="Comprehensive automation capabilities for your systems"
        />
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className="bg-card p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300 hover:-translate-y-1 hover:shadow-lg hover:shadow-[#1BC595]/10"
            >
              <div className="flex justify-center mb-6">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold text-center mb-4 text-foreground">
                {feature.title}
              </h3>
              <ul className="space-y-3 mx-auto max-w-[85%]">
                {feature.items.map((item, idx) => (
                  <li key={idx} className="flex items-start text-muted-foreground">
                    <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-3 mt-2" />
                    <span className="flex-1">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;