import React from 'react';
import { CheckCircle } from 'lucide-react';

const ComplianceStandards = () => {
  const standards = [
    {
      name: "NIST 800-53",
      description: "Security and Privacy Controls"
    },
    {
      name: "FIPS 140-3",
      description: "Cryptographic Module Validation"
    },
    {
      name: "DISA STIG",
      description: "Security Technical Implementation Guides"
    },
    {
      name: "CMMC",
      description: "Cybersecurity Maturity Model Certification"
    },
    {
      name: "ISO 27001",
      description: "Information Security Management"
    }
  ];

  return (
    <div className="bg-card p-6 rounded-lg border border-border">
      <h4 className="text-xl font-semibold mb-6 text-[#1BC595]">Compliance Standards</h4>
      <div className="space-y-4">
        {standards.map((standard, index) => (
          <div key={index} className="flex items-start">
            <CheckCircle className="w-5 h-5 text-[#1BC595] mt-1 flex-shrink-0" />
            <div className="ml-4">
              <h5 className="font-semibold text-foreground">{standard.name}</h5>
              <p className="text-sm text-muted-foreground">{standard.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComplianceStandards;