export const ROUTES = {
  HOME: '/',
  SOLUTIONS: '/solutions',
  SOFTWARE: {
    ATD: '/solutions/air-gap-transfer-solution',
    SCCM: '/solutions/sccm'
  },
  AUTOMATION: {
    ROOT: '/solutions/automation',
    WORKFLOWS: '/solutions/automation/workflows'
  },
  ABOUT: {
    OVERVIEW: '/about',
    LEADERSHIP: '/about/leadership',
    MISSION: '/about/mission'
  },
  RESOURCES: {
    ROOT: '/resources',
    BLOG: '/resources/blog',
    CASE_STUDIES: '/resources/case-studies',
    ROI: '/resources/roi-tools'
  },
  LEGAL: {
    PRIVACY: '/legal/privacy',
    TERMS: '/legal/terms'
  },
  CONTACT: '/contact'
} as const;