import React from 'react';
import { Shield, Lock, FileCheck, History } from 'lucide-react';

const SecurityFeatures = () => {
  const features = [
    {
      icon: <Lock className="w-8 h-8 text-[#1BC595]" />,
      title: "FIPS 140-3 Encryption",
      description: "Military-grade encryption for data at rest and in transit"
    },
    {
      icon: <Shield className="w-8 h-8 text-[#1BC595]" />,
      title: "Access Control",
      description: "Role-based authentication and authorization"
    },
    {
      icon: <History className="w-8 h-8 text-[#1BC595]" />,
      title: "Audit Logging",
      description: "Comprehensive activity and change tracking"
    },
    {
      icon: <FileCheck className="w-8 h-8 text-[#1BC595]" />,
      title: "Integrity Verification",
      description: "Digital signatures and hash verification"
    }
  ];

  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6 bg-background rounded-xl p-8 border border-border">
      {features.map((feature, index) => (
        <div key={index} className="bg-card p-6 rounded-lg border border-border hover:border-[#1BC595]/50 transition-colors">
          <div className="flex items-center mb-4">
            {feature.icon}
            <h4 className="text-lg font-semibold ml-3 text-foreground">{feature.title}</h4>
          </div>
          <p className="text-muted-foreground">{feature.description}</p>
        </div>
      ))}
    </div>
  );
};

export default SecurityFeatures;