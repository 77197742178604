import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { CTASection } from '@/components/ui/cta-section';
import { Target, Shield, Users, Lightbulb } from 'lucide-react';

const MissionPage = () => {
  const values = [
    {
      icon: <Target className="w-8 h-8 text-[#1BC595]" />,
      title: "Innovation",
      description: "Continuously advancing the capabilities of systems automation"
    },
    {
      icon: <Shield className="w-8 h-8 text-[#1BC595]" />,
      title: "Security",
      description: "Ensuring secure and compliant automation solutions"
    },
    {
      icon: <Users className="w-8 h-8 text-[#1BC595]" />,
      title: "Client Success",
      description: "Delivering automation solutions that drive measurable efficiency gains"
    },
    {
      icon: <Lightbulb className="w-8 h-8 text-[#1BC595]" />,
      title: "Excellence",
      description: "Crafting reliable and efficient automation solutions"
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center mb-16">
          <SectionHeader
            title="Our Mission & Values"
            description="Empowering organizations with advanced systems management and automation"
          />
          
          <div className="bg-card p-8 rounded-xl border border-border mt-12">
            <h2 className="text-2xl font-semibold mb-4 text-foreground">Our Mission</h2>
            <p className="text-lg text-muted-foreground leading-relaxed">
              To revolutionize enterprise systems management through innovative automation solutions and expert 
              SCCM integration. We specialize in secure automation for air-gapped environments, compliance 
              automation, and custom tools that transform how organizations manage their IT infrastructure.
            </p>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {values.map((value, index) => (
            <div key={index} className="bg-card p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300">
              <div className="flex items-center mb-4">
                {value.icon}
                <h3 className="text-xl font-semibold ml-4 text-foreground">{value.title}</h3>
              </div>
              <p className="text-muted-foreground">{value.description}</p>
            </div>
          ))}
        </div>
      </div>
      
      <CTASection
        title="Share Our Vision?"
        description="Join us in transforming enterprise IT operations"
      />
    </div>
  );
};

export default MissionPage;