import React from 'react';
import { Quote } from 'lucide-react';
import { SectionHeader } from '../../ui/section-header';

const Testimonials = () => {
  const testimonials = [
    {
      quote: "LevelLogik's solutions have revolutionized our IT operations. The automation capabilities have reduced our management overhead by 85%.",
      author: "Sarah Chen",
      title: "CTO",
      company: "Global Defense Contractor"
    },
    {
      quote: "Complete visibility into our systems without compromising security. The ROI was evident within the first quarter.",
      author: "Michael Rodriguez",
      title: "IT Director",
      company: "Government Agency"
    },
    {
      quote: "Asset tracking and compliance reporting that used to take weeks now happens in hours. A game-changer for our operations.",
      author: "David Thompson",
      title: "CISO",
      company: "Energy Sector Enterprise"
    }
  ];

  return (
    <section className="py-20 bg-card" id="testimonials">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Success Stories"
          description="See how organizations are transforming their IT operations"
        />
        
        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index} 
              className="bg-background p-8 rounded-xl relative border border-border shadow-lg hover:border-[#1BC595]/50 transition-all duration-300 hover:-translate-y-1"
            >
              <Quote className="w-8 h-8 text-[#1BC595] absolute -top-4 -left-4 bg-background p-1 rounded-full" />
              <p className="text-lg mb-6 text-muted-foreground leading-relaxed">
                "{testimonial.quote}"
              </p>
              <div className="border-t border-border pt-4">
                <p className="font-semibold text-foreground">{testimonial.author}</p>
                <p className="text-sm text-muted-foreground">{testimonial.title}</p>
                <p className="text-[#1BC595] text-sm">{testimonial.company}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;