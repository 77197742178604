import React from 'react';
import { Clock, DollarSign, Shield } from 'lucide-react';
import ComparisonTable from './ComparisonTable';
import CostCalculator from './CostCalculator';
import { SectionHeader } from '../../ui/section-header';
import { FeatureCard } from '../../ui/feature-card';
import type { IconFeature } from '@/lib/types/components';

const EfficiencyComparison = () => {
  const benefits: IconFeature[] = [
    {
      icon: <Clock className="w-12 h-12 text-[#1BC595]" />,
      title: "Time Efficiency",
      description: "Reduce system management time by up to 80% with automated workflows and streamlined processes."
    },
    {
      icon: <DollarSign className="w-12 h-12 text-[#1BC595]" />,
      title: "Cost Savings",
      description: "Save $250-$425 per system annually through improved efficiency and reduced manual labor."
    },
    {
      icon: <Shield className="w-12 h-12 text-[#1BC595]" />,
      title: "Enhanced Security",
      description: "Minimize human error and maintain consistent security baselines through automation."
    }
  ];

  return (
    <section className="py-20 bg-background" id="efficiency">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Efficiency Analysis"
          description="See how our solution transforms your air-gapped system management"
        />

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {benefits.map((benefit, index) => (
            <FeatureCard
              key={index}
              {...benefit}
              className="bg-card"
            />
          ))}
        </div>

        <div className="bg-card rounded-xl border border-border p-6 mb-16 shadow-lg">
          <h3 className="text-2xl font-semibold mb-8 text-center text-[#1BC595]">
            Time Comparison: Traditional vs Modern
          </h3>
          <ComparisonTable />
        </div>

        <CostCalculator />
      </div>
    </section>
  );
};

export default EfficiencyComparison;