import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { CTASection } from '@/components/ui/cta-section';
import { Bot } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ROUTES } from '@/lib/constants/routes';

export const AutomationPage = () => {
  const navigate = useNavigate();
  
  const solutions = [
    {
      icon: <Bot className="w-12 h-12 text-[#1BC595]" />,
      title: "Custom AI Workflows",
      description: "Intelligent automation solutions powered by AI",
      features: [
        "AI-driven process optimization",
        "Custom workflow automation",
        "Intelligent decision making"
      ],
      link: ROUTES.AUTOMATION.WORKFLOWS
    }
  ];

  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Automation Solutions"
          description="Our automation solutions combine cutting-edge AI technology with industry expertise to streamline your workflows and boost productivity."
        />

        <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-8 mt-16">
          {solutions.map((solution, index) => (
            <div
              key={index}
              className="bg-card p-8 rounded-lg border border-border hover:border-primary/50 transition-colors"
            >
              <div className="flex flex-col md:flex-row items-start md:items-center gap-6">
                <div className="flex-shrink-0">{solution.icon}</div>
                <div className="flex-grow">
                  <h3 className="text-2xl font-semibold mb-2">{solution.title}</h3>
                  <p className="text-muted-foreground mb-4">{solution.description}</p>
                  <ul className="list-disc list-inside space-y-2 text-muted-foreground mb-6">
                    {solution.features.map((feature, idx) => (
                      <li key={idx}>{feature}</li>
                    ))}
                  </ul>
                  <Button
                    onClick={() => navigate(solution.link)}
                    className="bg-[#1BC595] hover:bg-[#1BC595]/90"
                  >
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <CTASection
          title="Ready to Automate?"
          description="Get started with our automation solutions today and transform your business operations."
        />
      </div>
    </div>
  );
};