import React from 'react';
import { Linkedin, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../lib/constants/routes';
import branding from '../../lib/config/branding.json';

const Footer = () => {
  const { company, branding: brandingConfig } = branding.global;
  const { contact, social } = company;
  
  return (
    <footer className="bg-background border-t border-border py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8 mb-8">
          <div>
            <div className="flex items-center gap-2 mb-4">
              <img src={brandingConfig.logo.path} alt={brandingConfig.logo.alt} className="h-8 w-auto" />
              <h3 className="text-xl font-semibold text-foreground">{company.name}</h3>
            </div>
            <p className="text-muted-foreground">
              {company.tagline}
            </p>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold mb-4">Contact</h3>
            <ul className="space-y-2 text-muted-foreground">
              <li>{contact.phone}</li>
              <li>
                <a 
                  href={`mailto:${contact.email}`}
                  className="hover:text-foreground transition-colors"
                >
                  {contact.email}
                </a>
              </li>
              {contact.address.street && <li>{contact.address.street}</li>}
              {(contact.address.city || contact.address.state) && (
                <li>
                  {[contact.address.city, contact.address.state]
                    .filter(Boolean)
                    .join(', ')}
                </li>
              )}
            </ul>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a 
                href={social.linkedin}
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-muted-foreground hover:text-foreground transition-colors"
              >
                <Linkedin className="w-5 h-5" />
              </a>
              <a 
                href={`mailto:${contact.email}`}
                className="text-muted-foreground hover:text-foreground transition-colors"
              >
                <Mail className="w-5 h-5" />
              </a>
            </div>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to={ROUTES.LEGAL.PRIVACY}
                  className="text-muted-foreground hover:text-foreground transition-colors"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTES.LEGAL.TERMS}
                  className="text-muted-foreground hover:text-foreground transition-colors"
                >
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-border pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-muted-foreground text-sm">
              &copy; {new Date().getFullYear()} {company.name}. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;