import { FrequencyMap } from './types';

export const FREQUENCY_MAP: FrequencyMap = {
  quarterly: { times: 4, label: 'Quarterly' },
  monthly: { times: 12, label: 'Monthly' },
  biweekly: { times: 26, label: 'Bi-Weekly' }
} as const;

export const TRADITIONAL_HOURS = {
  min: 3.25,  // 195 minutes (Initial: 75 + SCCM: 60 + Updates: 60)
  max: 5.4    // 324 minutes (Initial: 125 + SCCM: 95 + Updates: 110)
} as const;

export const MODERN_HOURS = {
  min: 0.5,   // 30 minutes (Collection: 5 + SCCM: 15 + Updates: 10)
  max: 0.75   // 45 minutes (Collection: 10 + SCCM: 20 + Updates: 15)
} as const;