import React, { useState } from 'react';
import { Calculator, DollarSign, Clock, TrendingDown, Shield } from 'lucide-react';
import { calculateSavings } from './utils';
import { FREQUENCY_MAP } from './constants';
import './slider.css';

const CostCalculator = () => {
  const [systems, setSystems] = useState(100);
  const [techRate, setTechRate] = useState(50);
  const [frequency, setFrequency] = useState('quarterly');

  const costs = calculateSavings(systems, techRate, frequency);

  return (
    <div className="bg-card p-8 rounded-xl border border-border shadow-lg">
      <div className="flex items-center gap-3 mb-8">
        <Calculator className="w-8 h-8 text-[#1BC595]" />
        <h3 className="text-2xl font-bold text-foreground">
          ROI Calculator
        </h3>
      </div>
      
      <div className="grid md:grid-cols-3 gap-8 mb-8">
        <div>
          <label className="block text-sm font-medium text-muted-foreground mb-2">
            Number of Systems
          </label>
          <input
            type="range"
            min="10"
            max="1000"
            value={systems}
            onChange={(e) => setSystems(Number(e.target.value))}
            className="custom-range-slider w-full h-2 bg-gradient-to-r from-primary/20 to-primary/40 rounded-lg appearance-none cursor-pointer"
          />
          <div className="text-center mt-2 text-lg font-semibold">
            {systems} Systems
          </div>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-muted-foreground mb-2">
            Technician Hourly Rate
          </label>
          <input
            type="range"
            min="25"
            max="100"
            step="5"
            value={techRate}
            onChange={(e) => setTechRate(Number(e.target.value))}
            className="custom-range-slider w-full h-2 bg-gradient-to-r from-primary/20 to-primary/40 rounded-lg appearance-none cursor-pointer"
          />
          <div className="text-center mt-2 text-lg font-semibold">
            ${techRate}/hour
          </div>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-muted-foreground mb-2">
            Inventory Frequency
          </label>
          <select
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            className="w-full p-2 bg-background border border-border rounded-lg focus:ring-2 focus:ring-primary focus:border-primary"
          >
            <option value="quarterly">Quarterly (Minimum)</option>
            <option value="monthly">Monthly (Recommended)</option>
            <option value="biweekly">Bi-Weekly (Optimal)</option>
          </select>
          <div className="text-center mt-2 text-sm text-muted-foreground">
            {FREQUENCY_MAP[frequency].times}x per year
          </div>
        </div>
      </div>

      <div className="space-y-8">
        <div>
          <h4 className="text-lg font-semibold mb-4 flex items-center">
            <Clock className="w-5 h-5 mr-2 text-[#1BC595]" />
            Cost Per {FREQUENCY_MAP[frequency].label} Cycle
          </h4>
          <div className="grid md:grid-cols-2 gap-4">
            <div className="p-4 bg-destructive/10 rounded-lg border border-destructive/20">
              <div className="text-sm text-muted-foreground">Traditional Approach</div>
              <div className="text-xl font-bold text-destructive">{costs.perCycle.traditional}</div>
              <div className="text-sm text-muted-foreground mt-1">Per Cycle</div>
            </div>
            <div className="p-4 bg-primary/10 rounded-lg border border-primary/20">
              <div className="text-sm text-muted-foreground">Modern Solution</div>
              <div className="text-xl font-bold text-[#1BC595]">{costs.perCycle.modern}</div>
              <div className="text-sm text-muted-foreground mt-1">Per Cycle</div>
            </div>
          </div>
        </div>

        <div>
          <h4 className="text-lg font-semibold mb-4 flex items-center">
            <DollarSign className="w-5 h-5 mr-2 text-[#1BC595]" />
            Annual Analysis
          </h4>
          <div className="grid md:grid-cols-3 gap-4">
            <div className="p-4 bg-destructive/10 rounded-lg border border-destructive/20">
              <div className="text-sm text-muted-foreground">Traditional Annual Cost</div>
              <div className="text-xl font-bold text-destructive">{costs.annual.traditional}</div>
              <div className="text-sm text-muted-foreground mt-1">Per Year</div>
            </div>
            <div className="p-4 bg-primary/10 rounded-lg border border-primary/20">
              <div className="text-sm text-muted-foreground">Modern Annual Cost</div>
              <div className="text-xl font-bold text-[#1BC595]">{costs.annual.modern}</div>
              <div className="text-sm text-muted-foreground mt-1">Per Year</div>
            </div>
            <div className="p-4 bg-primary/10 rounded-lg border border-primary/20">
              <div className="text-sm text-muted-foreground">Annual Savings</div>
              <div className="text-xl font-bold text-[#1BC595]">{costs.annual.savings}</div>
              <div className="text-sm text-muted-foreground mt-1">Per Year</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8 grid md:grid-cols-2 gap-6">
        <div className="p-4 bg-background rounded-lg border border-border">
          <div className="flex items-center gap-2 text-[#1BC595] mb-2">
            <TrendingDown className="w-5 h-5" />
            <h4 className="font-semibold">ROI Highlights</h4>
          </div>
          <ul className="space-y-2 text-sm text-muted-foreground">
            <li>• {FREQUENCY_MAP[frequency].label} inventories for better security</li>
            <li>• 75-85% time reduction per inventory cycle</li>
            <li>• Increased inventory frequency without extra effort</li>
            <li>• Proactive security and compliance stance</li>
          </ul>
        </div>
        
        <div className="p-4 bg-background rounded-lg border border-border">
          <div className="flex items-center gap-2 text-[#1BC595] mb-2">
            <Shield className="w-5 h-5" />
            <h4 className="font-semibold">Security Benefits</h4>
          </div>
          <ul className="space-y-2 text-sm text-muted-foreground">
            <li>• Automated workflows reduce human error</li>
            <li>• Consistent security baselines</li>
            <li>• Complete audit trails</li>
            <li>• Enhanced compliance reporting</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CostCalculator;