import React from 'react';
import { Contact } from '@/components/sections';

const ContactPage = () => {
  return (
    <div className="pt-24">
      <Contact />
    </div>
  );
};

export default ContactPage;
export { ContactPage };