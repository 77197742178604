import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { CTASection } from '@/components/ui/cta-section';

const LeadershipTeam = () => {
  const leaders = [
    {
      name: "Rob Olson",
      title: "Founder & CEO",
      description: "30+ years of experience in enterprise systems management and automation",
      expertise: [
        "Enterprise Software Development",
        "Systems Management",
        "IT Automation"
      ]
    },
    // Additional leadership team members can be added here
  ];

  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="Leadership Team"
          description="Meet the experts driving innovation in enterprise IT solutions"
        />
        
        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto mt-12">
          {leaders.map((leader, index) => (
            <div key={index} className="bg-card p-8 rounded-xl border border-border hover:border-[#1BC595]/50 transition-all duration-300">
              <h3 className="text-2xl font-semibold mb-2 text-foreground">{leader.name}</h3>
              <p className="text-[#1BC595] mb-4">{leader.title}</p>
              <p className="text-muted-foreground mb-6">{leader.description}</p>
              <div className="space-y-2">
                {leader.expertise.map((item, idx) => (
                  <div key={idx} className="flex items-center text-muted-foreground">
                    <span className="w-1.5 h-1.5 bg-[#1BC595] rounded-full mr-2" />
                    {item}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <CTASection
        title="Want to Join Our Team?"
        description="We're always looking for talented individuals to help drive innovation"
      />
    </div>
  );
};

export default LeadershipTeam;