import React from 'react';
import { SectionHeader } from '@/components/ui/section-header';
import { CTASection } from '@/components/ui/cta-section';
import { EfficiencyComparison } from '@/components/sections';

const ROIToolsPage = () => {
  return (
    <div className="min-h-screen pt-32">
      <div className="container mx-auto px-4">
        <SectionHeader
          title="ROI Calculator"
          description="Calculate your potential savings with our solutions"
        />
        
        <div className="max-w-5xl mx-auto mt-12">
          <EfficiencyComparison />
        </div>
      </div>
      
      <CTASection
        title="See the Value for Yourself"
        description="Let's calculate your potential ROI with our solutions"
      />
    </div>
  );
};

export default ROIToolsPage;